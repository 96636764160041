export const SETTING_NAME_MAP = {
  AddEnabled: 'functionsEnabled',
  AttachEnabled: 'functionsEnabled',
  AttachmentEnabled: 'functionsEnabled',
  Asset_UniqueCode: 'enforceUniqueCode',
  Asset_ColumnVisible: 'visibleColumns',
  Assets_EnabledAssetType: 'enabledAssetTypes',
  Begin: 'beginsOn',
  CategoriesVisible: 'visibleCategories',
  BulkAddEnabled: 'functionsEnabled',
  ColumnVisible: 'visibleColumns',
  DetailVisibleColumns: 'detailColumnVisible',
  Component_ColumnVisible: 'visibleColumns',
  Component_MasterListSelection: 'masterlistSelections',
  Component_RequiredFields: 'requiredFields',
  Component_UniqueCode: 'enforceUniqueCode',
  DisableDuringActiveStatusAction: 'disableStatusDuringAction',
  LeftNav_Icon: 'icons',
  Default: 'defaultTab',
  DeleteEnabled: 'functionsEnabled',
  DetachEnabled: 'functionsEnabled',
  DownloadAttachment: 'functionsEnabled',
  EditAttachment: 'functionsEnabled',
  DeleteAttachment: 'functionsEnabled',
  EditEnabled: 'functionsEnabled',
  End: 'endsOn',
  FieldEnabled: 'enabledFields',
  FieldVisible: 'visibleFields',
  Header_FieldEnabled: 'headerFieldsEnabled',
  ImportEnabled: 'functionsEnabled',
  Order: 'tabOrder',
  PrintManage_PrintSettings_PrintProfileEnabled: 'printProfilesEnabled',
  PrintManage_PrintSettings_DefaultPrintProfile: 'defaultPrintProfile',
  PrintSelectedEnabled: 'functionsEnabled',
  ReorderEnabled: 'functionsEnabled',
  RestorationEnabled: 'functionsEnabled',
  RowColor_Status: 'statusColors',
  SameUserCannot: 'sameUserCannot',
  ScanImportEnabled: 'functionsEnabled',
  SignOffEnabled: 'functionsEnabled',
  SignOffSignature: 'functionsEnabled',
  SignOnSignature: 'functionsEnabled',
  SignOn: 'functionsEnabled',
  StatusCheck: 'functionsEnabled',
  StatusActionCheck: 'functionsEnabled',
  Visible: 'visibleTabs',
  TagNotesEnabled: 'functionsEnabled',
  AddTagEnabled: 'functionsEnabled',
  RemoveTagEnabled: 'functionsEnabled',
  CaptureAttachment: 'functionsEnabled',
  UploadAttachment: 'functionsEnabled',
  ResetStepItems: 'functionsEnabled',
  ResetItems: 'functionsEnabled'
};

export const SETTING_VALUE_MAP = {
  acceptableExtensions: 'VALUE',
  addons: 'TAB',
  allowImportAssets: 'BOOLEAN',
  allowImportComponents: 'BOOLEAN',
  allowImportNonStandard: 'BOOLEAN',
  allowImportStandard: 'BOOLEAN',
  allow: 'ATTRIBUTE_LIST',
  assets: 'TAB',
  associatedUserEnabled: 'ATTRIBUTE_LIST',
  attachable: 'BOOLEAN_ATTRIBUTE_OBJECT',
  attachedPermits: 'TAB',
  attachedValidityCheck: 'BOOLEAN_ATTRIBUTE_OBJECT',
  attachments: 'TAB',
  attachmentAcceptableExtensions: 'VALUE',
  attachmentAspectRatio: 'VALUE',
  attachmentMaxFileSize: 'VALUE',
  attachmentMaxHeight: 'VALUE',
  attachmentMaxWidth: 'VALUE',
  attachmentMinHeight: 'VALUE',
  attachmentMinWidth: 'VALUE',
  autoAddItems: 'ATTRIBUTE_LIST',
  autoAdvance: 'VALUE',
  badgeVisible: 'ATTRIBUTE_LIST',
  beginsOn: 'VALUE',
  cardViewHeaderVisible: 'ATTRIBUTE_LIST',
  cardViewMaxWidth: 'ATTRIBUTE_LIST',
  cardViewColor:'VALUE_OBJECT',
  categoriesEnabled: 'ATTRIBUTE_LIST',
  changeDateEnabled: 'BOOLEAN_VALUE_OBJECT',
  checkboxEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  checkRequired: 'BOOLEAN',
  childCategoriesEnabled: 'ATTRIBUTE_LIST',
  columnEditable: 'ATTRIBUTE_LIST',
  columnVisible: 'ATTRIBUTE_LIST',
  componentStatusSameUserCannot: 'ATTRIBUTE_CONFIG_OBJECT',
  componentStatusScanEnabled: 'ATTRIBUTE_LIST',
  componentStatusRequired: 'ATTRIBUTE_LIST',
  componentAuthorizationEnabled: 'BOOLEAN',
  componentAuthorizationBlockStatus: 'BOOLEAN',
  DetailVisibleColumns: 'ATTRIBUTE_LIST',
  discussion: 'TAB',
  commonTagEnabled: 'BOOLEAN',
  controlIndividualRow: 'BOOLEAN_VALUE_OBJECT',
  defaultAddonType: 'VALUE',
  dateFormat: 'VALUE',
  dateTimeFormat: 'VALUE',
  defaultCategory: 'VALUE',
  defaultLanguage: 'VALUE',
  defaultOrder: 'VALUE',
  defaultPrintProfile: 'VALUE',
  defaultTab: 'VALUE',
  defaultTagType: 'VALUE',
  detailsEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  detailsMasterListSelection: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  detailsVisible: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  detailsVisibleWhenChecked: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  disableStatusDuringAction: 'BOOLEAN_ATTRIBUTE_OBJECT',
  enabled: 'ATTRIBUTE_LIST',
  enabledAssetTypes: 'ATTRIBUTE_LIST',
  enabledFields: 'ATTRIBUTE_LIST',
  endsOn: 'ATTRIBUTE',
  enforceUniqueCode: 'BOOLEAN',
  expirationHours: 'VALUE',
  fieldVisibleTask: 'BOOLEAN',
  functionsEnabled: 'FUNCTIONS_ENABLED',
  functionEnabledAssetCodeScan: 'BOOLEAN',
  functionEnabledAssetDescScan: 'BOOLEAN',
  general: 'TAB',
  hazardAnalysis: 'TAB',
  headerfieldsEnabled: 'VALUE_OBJECT',
  history: 'TAB',
  infoUrl: 'VALUE',
  inTestIsChecked: 'BOOLEAN',
  inTestIsUnChecked: 'BOOLEAN',
  requiredIfStatus : 'ATTRIBUTE_LIST',
  requiredIfStatusOnValidityCheck: "ATTRIBUTE_LIST",
  isChecked: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  labelEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  loginActiveDirectoryDomain: 'VALUE',
  loginActiveDirectoryFilter: 'VALUE',
  loginActiveDirectoryInterval: 'VALUE',
  loginActiveDirectoryPath: 'VALUE',
  loginInactivityTimeout: 'VALUE',
  loginMethod: 'VALUE',
  loginRefreshTimeout: 'VALUE',
  loginTimeout: 'VALUE',
  masterlistSelections: 'ATTRIBUTE_LIST',
  maxFileSize: 'VALUE',
  maxHeight: 'VALUE',
  maxWidth: 'VALUE',
  methodsEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  minHeight: 'VALUE',
  minWidth: 'VALUE',
  mitigationEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  methods: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  methodsMasterListSelection: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  methodsVisible: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  methodsVisibleWhenChecked: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  mitigation: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  mitigationsMasterListSelection: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  mitigationsVisible: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  mitigationsVisibleWhenChecked: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  notes: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  notesEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  notesMasterListSelection: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  notesVisible: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  notesVisibleWhenChecked: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  observeDaylightSavings: 'VALUE',
  oneClickEnabled: 'BOOLEAN_ATTRIBUTE_OBJECT',
  oneClickStatusChange: 'BOOLEAN_ATTRIBUTE_OBJECT',
  oneClickStatusActionChange: 'BOOLEAN_ATTRIBUTE_OBJECT',
  onlyAllowCreateFrom: 'ATTRIBUTE_LIST',
  passwordDefered: 'BOOLEAN_ATTRIBUTE_OBJECT',
  passwordEnabled: 'BOOLEAN_ATTRIBUTE_OBJECT',
  passwordEnabledStatusAction: 'BOOLEAN_ATTRIBUTE_OBJECT',
  preFilterStatus: 'ATTRIBUTE_LIST',
  printProfilesEnabled: 'BOOLEAN',
  procedure: 'TAB',
  questionEnabled: 'BOOLEAN_ATTRIBUTE_OBJECT',
  reasonEnabled: 'BOOLEAN_ATTRIBUTE_OBJECT',
  reasonSelectorEnabled: 'BOOLEAN_ATTRIBUTE_OBJECT',
  requiredFields: 'ATTRIBUTE_LIST',
  results: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  resultsEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  resultsRange: 'VALUE_NESTED_ATTRIBUTE_OBJECT',
  removedIsChecked: 'BOOLEAN',
  removedIsUnChecked: 'BOOLEAN',
  sameUserCannot: 'ATTRIBUTE_LIST',
  scanCodeRegex: 'VALUE',
  assetsSearchComponentCodes: 'BOOLEAN',
  signOffBefore: 'BOOLEAN_ATTRIBUTE_OBJECT',
  signOffBeforeStatusAction: 'BOOLEAN_ATTRIBUTE_OBJECT',
  statuscheck: 'Boolean',
  statusActionCheck: 'BOOLEAN',
  statusColors: 'VALUE_OBJECT',
  statusDisabledUntil: 'ATTRIBUTE_CONFIG_OBJECT',
  statusDisabledUntilOnValidityCheck: 'ATTRIBUTE_CONFIG_OBJECT',
  statusEnabled: 'BOOLEAN',
  statusUserDefault: 'ATTRIBUTE_CONFIG_OBJECT',
  steps: 'TAB',
  tagAdded: 'BOOLEAN',
  tags: 'TAB',
  tabOrder: 'VALUE_OBJECT',
  tagFieldValidityCheckValue: 'ATTRIBUTE_CONFIG_OBJECT',
  templatesCategoriesVisibleStandard: 'ATTRIBUTE_LIST',
  templatesCategoriesVisibleNonStandard: 'ATTRIBUTE_LIST',
  templatesAutoFilterEnabledTaskNonStandard: 'BOOLEAN',
  templatesAutoFilterEnabledTaskStandard: 'BOOLEAN',
  templatesInfoOverwrite: 'BOOLEAN',
  templatesInfoOverwriteStandard: 'BOOLEAN',
  templatesInfoOverwriteNonStandard: 'BOOLEAN',
  templatesNonStandardAutoFilterEnabledAssetCode: 'BOOLEAN',
  templatesNonStandardAutoFilterEnabledAssetDescription: 'BOOLEAN',
  templatesPermitStatusEnabledStandard: 'ATTRIBUTTE_LIST',
  templatesPermitStatusEnabledNonStandard: 'ATTRIBUTTE_LIST',
  templatesStandardAutoFilterEnabledAssetCode: 'BOOLEAN',
  templatesStandardAutoFilterEnabledAssetDescription: 'BOOLEAN',
  templatesVisibleStandard: 'BOOLEAN',
  templatesVisibleNonStandard: 'BOOLEAN',
  templatesVisible: 'BOOLEAN',
  timeFormat: 'VALUE',
  timeZone: 'VALUE',
  textChecked: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  textCheckedEnabled: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  thresholdBlock: 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT',
  trackingEnabled: 'BOOLEAN_VALUE_OBJECT',
  varietyTypeVisible: 'BOOLEAN',
  visibleCategories: 'ATTRIBUTE_LIST',
  visibleColumns: 'ATTRIBUTE_LIST',
  detailColumnVisible: 'ATTRIBUTE_LIST',
  visibleFields: 'ATTRIBUTE_LIST',
  visibleTabs: 'ATTRIBUTE_LIST',
  workorders: 'TAB'
};
